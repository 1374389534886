/**
 * Host App Layout
 * 
 * Defines the layout for the web app
 */

import React from 'react'
import PhotoUpload from '../components/host/photos/photo-upload'
import SEO from '../components/seo'
import Transition from '../components/transition'

const HostLayout = ({ children, location }) => (
  <>
    <SEO title="Watson Adventures Host Dashboard" />
    <div className={`page-wrap host-page`}>
      <div className={`site-content container-fluid px-0`}>{children}</div>
    </div>
  </>
)

export default HostLayout
