/**
 * Share to Social Modal
 *
 * Displays a modal with buttons to share the current URL and text on Facebook and via email. 
 * It uses the react-share library to implement the sharing functionality. 
 * The component takes in four props: show (a boolean to determine whether the modal is 
 * displayed), modalClose (a function to close the modal), url (the URL to be shared), 
 * and text (the text to be shared). The component also defines propTypes for each of 
 * these props.
 */
import React from 'react'
import Modal from 'react-bootstrap/Modal'
import PropTypes from 'prop-types'

import { EmailShareButton, FacebookShareButton, EmailIcon, FacebookIcon } from 'react-share'

export default class ShareModal extends React.Component {
  render() {
    const { show, modalClose, url, text } = this.props

    return (
      <Modal show={show} onHide={modalClose} centered aria-labelledby="share-modal-title">
        <Modal.Header closeButton>
          <Modal.Title id="share-modal-title">Share To</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={`text-center mt-4`}>
            <FacebookShareButton
              className={`btn btn-block btn-danger text-uppercase mb-4 py-2 btn-share`}
              url={url}
              quote={text.replace(/(\r\n|\n|\r)/gm, ` `)}
              hashtag={`#WatsonAdventures`}>
              <FacebookIcon size={32} bgStyle={{ fill: `transparent` }} /> Facebook
            </FacebookShareButton>
            <EmailShareButton
              className={`btn btn-block btn-danger text-uppercase mb-4 py-2 btn-share`}
              url={url}
              body={text.replace(/(\r\n|\n|\r)/gm, ` `)}
              subject={`I just completed a scavenger hunt!`}
              separator={` `}>
              <EmailIcon size={32} bgStyle={{ fill: `transparent` }} />
              Email
            </EmailShareButton>
          </div>
          <button className={`btn btn-block btn-tiny mt-4`} onClick={modalClose}>
            Cancel
          </button>
        </Modal.Body>
      </Modal>
    )
  }
}

ShareModal.propTypes = {
  show: PropTypes.bool.isRequired,
  modalClose: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}
