/**
 * App Sticky Footer Component
 *
 * A "sticky" footer for the app. It displays the time left, total points earned, 
 * and other information at the bottom of the page. The component uses several other 
 * components, including a countdown timer, a score display, and SVG icons for 
 * sharing and displaying a photo gallery. The footer's content changes depending on 
 * the status of a scavenger hunt, which is determined by a function imported from a 
 * utility module. The component also uses a context object to manage state and 
 * display a modal for sharing.
 */

import React from 'react'
import CountDownTimer from './countdown-timer'
import Score from './score'
import { getHuntStatus } from '../utils/team-data'
import SvgShare from './svg/share'
import SvgPhotoGallery from './svg/gallery'
import Fade from 'react-reveal/Fade'
import AppContext from '../utils/context'
import { getCurrentHuntData } from '../utils/hunt-data'

export default class StickyFooter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hunt_status: `pending`,
    }
    this.update = this.update.bind(this)
  }

  componentDidMount() {
    this.interval = setInterval(this.update, 1000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  update() {
    this.setState({ hunt_status: getHuntStatus() })
  }

  render() {
    const data = getCurrentHuntData()
    const enable_timer = data.hunt !== undefined && data.hunt.enable_timer !== undefined ? data.hunt.enable_timer : true
    const enable_gallery = data.hunt !== undefined ? data.hunt.enable_gallery : false
    const enable_share = data.hunt !== undefined ? data.hunt.enable_share : false
    const { hunt_status } = this.state
    return (
      <AppContext.Consumer>
        {({ setShareModalShow }) => (
          <Fade up big>
            <div className={`site-footer sticky`}>
              <div className={`container pt-2 pb-3 px-0`}>
                <div className="row no-gutters">
                  {hunt_status == `ended` ? (
                    <>
                      <div className={`col`}>
                        <Score />
                      </div>
                      {enable_share && (
                        <div className={`col`}>
                          <button className={`btn btn-block btn-footer`} onClick={() => setShareModalShow(true)}>
                            <SvgShare />
                            <span className="d-block">Share</span>
                          </button>
                        </div>
                      )}
                      {enable_gallery && (
                        <div className={`col`}>
                          <a
                            className="btn btn-block btn-footer"
                            href={`/gallery/?code=${data.hunt.gallery_code}`}
                            target="_blank"
                            rel="noopener noreferrer">
                            <SvgPhotoGallery />
                            <span className="d-block">Gallery</span>
                          </a>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <div className="col-6">{enable_timer && <CountDownTimer />}</div>
                      <div className={`col-6 text-right`}>
                        <Score />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </Fade>
        )}
      </AppContext.Consumer>
    )
  }
}
