/**
 * Settings Panel Component
 *
 * Opens when the settings gear icon is pressed.
 * It includes form inputs for updating user data, such as team name and email, and allows the user to become the team captain. 
 * It also displays a QR code for sharing with teammates and includes a modal for changing the team captain. 
 * The component uses the FloatingInput component for form inputs and the Modal component for the modal.
 */

import React, { useContext, useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'

import { isTeamCaptain, getCurrentTeamData, teamUpdateName, updateTeamCaptain } from '../../utils/team-data'
import { getCurrentUser, updateUser } from '../../utils/auth'
import FloatingInput from '../floating-input'
import AppContext from '../../utils/context'
import SVGLoader from '../svg/loader'
import { getCurrentHuntData } from '../../utils/hunt-data'

const SettingsPanel = () => {
  const context = useContext(AppContext)
  const [data, setData] = useState({})
  const [team, setTeam] = useState({})
  const [userData, setUserData] = useState({
    guid: ``,
    name: ``,
    email: ``,
    teamName: ``,
    teamCaptain: null,
    player_count: ``,
  })
  const [modalCaptainShow, setModalCaptainShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const [is_dirty, setIsDirty] = useState(false)
  const [errors, setErrors] = useState({})

  //console.log(data)

  useEffect(() => {
    refresh()
    setData(getCurrentHuntData())
    setUserData({
      guid: getCurrentUser().guid,
      name: getCurrentUser().name,
      email: getCurrentUser().email,
      teamName: getCurrentTeamData().team_name ? getCurrentTeamData().team_name : ``,
      player_count: getCurrentTeamData().player_count ? getCurrentTeamData().player_count : ``,
      teamCaptain: isTeamCaptain(),
    })

    // refresh every 15 seconds
    const interval = setInterval(() => refresh(), 15 * 1000)

    return () => clearInterval(interval)
  }, [])

  const refresh = () => {
    const team_data = getCurrentTeamData()
    setTeam(team_data)
    if (!is_dirty) {
      setUserData({
        guid: getCurrentUser().guid,
        name: getCurrentUser().name,
        email: getCurrentUser().email,
        teamName: team_data.team_name ? team_data.team_name : ``,
        player_count: team_data.player_count ? team_data.player_count : ``,
        teamCaptain: isTeamCaptain(),
      })
    }
  }

  const validateFields = () => {
    let is_valid = true
    let errors = {}
    setErrors({})
    // name is required
    if (!userData.name.length) {
      ;(is_valid = false), (errors[`name`] = `Please enter your name`)
    }
    // email is optional, but should be validated if entered
    if (userData.email !== undefined && userData.email.length) {
      let lastAtPos = userData.email.lastIndexOf(`@`)
      let lastDotPos = userData.email.lastIndexOf(`.`)
      let email = userData.email

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          email.indexOf(`@@`) == -1 &&
          lastDotPos > 2 &&
          email.length - lastDotPos > 2
        )
      ) {
        is_valid = false
        errors[`email`] = `Please enter a valid email address`
      }
    }
    setErrors({ errors: errors })
    return is_valid
  }

  const handleSubmit = event => {
    event.preventDefault()
    let user_obj = {
      guid: userData.guid,
      name: userData.name,
      email: userData.email,
      teamName: userData.teamName,
      teamCaptain: userData.teamCaptain,
      player_count: userData.player_count,
    }

    let is_valid = validateFields()
    if (is_valid) {
      setLoading(true)
      setIsDirty(false)

      updateUser(user_obj).then(() => {
        if (isTeamCaptain()) {
          //console.log('updating team name')
          teamUpdateName(userData.teamName, userData.player_count).then(() => {
            setLoading(false)
            context.showAlert(`Settings saved!`)
          })
        } else {
          setLoading(false)
          context.showAlert(`Settings saved!`)
        }
      })
      setUserData(user_obj)
    }
  }

  // override the current team captain and become the team captain
  const becomeTeamCaptain = event => {
    event.preventDefault()
    setLoading(true)
    //console.log('becoming team captain')
    let user_id = getCurrentUser().guid
    updateTeamCaptain(user_id, true).then(() => {
      setModalCaptainShow(false)
      setLoading(false)
      context.showAlert(`You are now the team captain.`)
    })
  }

  const is_captain = isTeamCaptain()
  let enable_email_field = data.hunt !== undefined ? data.hunt.enable_email_field : true
  let enable_qr_code = data.hunt !== undefined ? data.hunt.enable_qr_code : true
  let showAlert = context.showAlert

  return (
    <div
      className="site-menu site-menu-settings"
      role="dialog"
      aria-expanded={`${context.settingsOpen ? `true` : `false`}`}>
      <div className={`px-4 mt-4`}>
        <div className={`row no-gutters`}>
          <div className={`col-3`}>
            <button
              className={`px-4 py-3 btn btn-link menu-toggle ${context.settingsOpen ? `settings-is-active` : ``}`}
              onClick={context.toggleSettings}
              title="Close Settings">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20">
                <g fill="none" fillRule="evenodd" stroke="#273A41" strokeLinecap="round" strokeWidth="2">
                  <path d="M1.5 1.5l16.867 16.867M18.5 1.5L1.633 18.367" />
                </g>
              </svg>
            </button>
          </div>
          <div className={`col-6`}>
            <h4 className={`text-center title h5 text-uppercase mt-3 mb-0`}>Hey there</h4>
          </div>
        </div>
      </div>
      <nav>
        <div className={`px-5 text-center`}>
          <form
            className={`form px-4 py-4`}
            method="post"
            onSubmit={handleSubmit}
            style={{
              backgroundColor: `rgba(238,234,225,0.60)`,
            }}>
            <FloatingInput
              name={`teamName`}
              placeholder={`Team Name`}
              onChange={(e) => {
                setUserData({ ...userData, teamName: e.target.value })
                setIsDirty(true)
              }}
              value={userData.teamName}
              disabled={!is_captain}
            />

            <FloatingInput
              name={`name`}
              placeholder={`Your Name`}
              onChange={(e) => {
                setUserData({ ...userData, name: e.target.value })
                setIsDirty(true)
              }}
              value={userData.name}
              error={errors[`name`]}
            />
            {enable_email_field && (
              <FloatingInput
                name={`email`}
                type={`email`}
                placeholder={`Your Email`}
                onChange={(e) => {
                  setUserData({ ...userData, email: e.target.value })
                  setIsDirty(true)
                }}
                value={userData.email}
                error={errors[`email`]}
              />
            )}

            {is_captain && (
              <FloatingInput
                name={`player_count`}
                placeholder={`Total Team Members`}
                onChange={(e) => {
                  setUserData({ ...userData, player_count: e.target.value })
                  setIsDirty(true)
                }}
                value={userData.player_count}
                error={errors[`player_count`]}
              />
            )}

            {loading ? (
              <div className={`d-flex align-items-center h-100 justify-content-center text-primary`}>
                <SVGLoader className={`text-primary`} />
              </div>
            ) : (
              <input className={`btn btn-success btn-block`} type="submit" value="Update" />
            )}
          </form>
          <div className={`mt-4`}>
            {is_captain ? (
              <p>You are the team captain.</p>
            ) : (
              <button className={`btn btn-block btn-primary`} onClick={() => setModalCaptainShow(true)}>
                Become the Team Captain.
              </button>
            )}
          </div>

          {enable_qr_code && (
            <div
              className="py-4 px-4 my-4"
              style={{
                backgroundColor: `rgba(238,234,225,0.60)`,
              }}>
              <h6 className={`h6 mb-2 text-center`}>Share this QR code with your teammates:</h6>
              <div className={`text-center`} dangerouslySetInnerHTML={{ __html: team.qr_code }} />
              <p className="text-center">
                Or go to play.watsonadventures.com and enter{` `}
                <span className="h5 fw-bold font-weight-bold">{team.access_code}</span>.
              </p>
            </div>
          )}
        </div>

        <Modal
          show={modalCaptainShow}
          onHide={() => setModalCaptainShow(false)}
          centered
          aria-labelledby="change-captain-modal-title">
          <Modal.Header closeButton>
            <Modal.Title id="change-captain-modal-title">Change Captain</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              First, make sure you and the current captain both have a strong internet connection. Then confirm that you
              will take over as captain below.
            </p>
            {loading ? (
              <div className={`text-center`}>
                <SVGLoader />
              </div>
            ) : (
              <>
                <button className={`btn btn-block btn-danger`} onClick={becomeTeamCaptain}>
                  Yes, I want to become captain.
                </button>
                <button className={`btn btn-block btn-success`} onClick={() => setModalCaptainShow(false)}>
                  No, I changed my mind.
                </button>
              </>
            )}
          </Modal.Body>
        </Modal>
      </nav>
    </div>
  )
}

export default SettingsPanel
