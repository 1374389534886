/**
 * Photo Upload Modal
 *
 * React component for uploading photos. It includes methods for handling the form submission, 
 * updating the state, and canceling the upload. The component uses image compression to 
 * reduce the size of the uploaded image and sends the data to the API endpoint using Axios. 
 * The component also includes a modal for displaying the upload form and a loading spinner 
 * while the upload is in progress.
 */

import React from 'react'
import Modal from 'react-bootstrap/Modal'
import axios from 'axios'
import { getCurrentUser } from '../../utils/auth'
import API from '../../utils/api'
import AppContext from '../../utils/context'
import imageCompression from 'browser-image-compression'
import SVGLoader from '../svg/loader'
import { getCurrentTeamData } from '../../utils/team-data'
import { faThermometerThreeQuarters } from '@fortawesome/free-solid-svg-icons'

const CancelToken = axios.CancelToken

class UploadPhotoModal extends React.Component {
  constructor(props) {
    super(props)
    this.handleUpdate = this.handleUpdate.bind(this)
    this.handleFileUpdate = this.handleFileUpdate.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.handleSlowUpload = this.handleSlowUpload.bind(this)
    this.cancelSlowUpload = this.cancelSlowUpload.bind(this)
    this.state = {
      image: ``,
      description: ``,
      loading: false,
      slow: false,
    }
    this.timeout = null
    this.cancel = null
  }

  handleSlowUpload() {
    console.log(`upload is taking a long time...`)
    this.setState({ slow: true })
  }

  cancelSlowUpload() {
    this.cancel()
  }

  handleSubmit(event) {
    event.preventDefault()
    let team_data = getCurrentTeamData()
    const { name } = getCurrentUser()
    this.setState({ loading: true })

    var compression_options = {
      maxSizeMB: 2,
      maxWidthOrHeight: 1920,
      useWebWorker: false,
    }

    console.log(this.props.image)
    imageCompression(this.props.image, compression_options)
      .then(compressedFile => {
        console.log(compressedFile)
        var imageFile = new File([compressedFile], compressedFile.name, { type: compressedFile.type })
        const post_data = new FormData()
        post_data.append(`author`, name)
        post_data.append(`image`, imageFile)
        post_data.append(`team_id`, team_data.id)
        post_data.append(`description`, this.state.description)

        // wait 7 seconds to see if this upload is taking too long
        this.timeout = setTimeout(this.handleSlowUpload, 7000)
        var that = this

        API.post(`session/${team_data.access_code}/${team_data.id}/photos/`, post_data, {
          cancelToken: new CancelToken(function executor(c) {
            // An executor function receives a cancel function as a parameter
            that.cancel = c
          }),
        })
          .then(res => {
            //console.log(res)
            clearTimeout(this.timeout)
            this.props.handleSubmit(res)
            this.setState({
              image: ``,
              description: ``,
              loading: false,
              slow: false,
            })
            this.showAlert(`Photo Uploaded!`)
            this.toggleRefresh()
          })
          .catch(function(error) {
            clearTimeout(that.timeout)
            console.log(error)
            that.setState({
              loading: false,
              slow: false,
            })
            if (error.__CANCEL__) {
              that.showAlert(`Upload Cancelled`)
              that.handleCancel()
            } else {
              that.showAlert(`Error Uploading Photo`)
            }
          })
      })
      .catch(function(error) {
        clearTimeout(that.timeout)
        console.log(error)
        that.setState({
          loading: false,
          slow: false,
        })
        if (error.__CANCEL__) {
          that.showAlert(`Upload Cancelled`)
          that.handleCancel()
        } else {
          that.showAlert(`Error Uploading Photo`)
        }
      })
  }

  handleUpdate(event) {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleFileUpdate(event) {
    this.setState({
      [event.target.name]: event.target.files[0],
    })
  }

  handleCancel() {
    this.setState({
      image: ``,
      description: ``,
    })
    this.props.modalClose()
  }

  render() {
    const { loading, slow } = this.state
    let buttonText = this.props.image !== `` ? this.props.image.name : `Choose Photo`
    return (
      <AppContext.Consumer>
        {({ showAlert, toggleRefresh }) => {
          this.showAlert = showAlert
          this.toggleRefresh = toggleRefresh
          return (
            <Modal
              show={this.props.show}
              onHide={this.props.modalClose}
              centered
              className="photo-upload-modal"
              aria-labelledby="upload-photo-modal-title">
              <Modal.Header closeButton>
                <Modal.Title id="upload-photo-modal-title" className={`text-center`}>
                  Upload a Photo
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className={``}>
                <form
                  className={`form mb-3`}
                  method="post"
                  onSubmit={event => {
                    this.handleSubmit(event)
                  }}>
                  <p className={`title h5 text-center`}>{buttonText}</p>
                  {loading ? (
                    <p className={`text-center pt-4`}>
                      <SVGLoader />
                    </p>
                  ) : (
                    <input className={`btn btn-block btn-success`} type="submit" value="Upload" />
                  )}
                  {slow && (
                    <>
                      <p className={`text-center pt-4`}>This seems to be taking a long time...</p>
                      <button className={`btn btn-block btn-tiny btn-cancel`} onClick={this.cancelSlowUpload}>
                        Cancel Upload
                      </button>
                    </>
                  )}
                </form>
                {!loading && (
                  <button className={`btn btn-block btn-tiny btn-cancel`} onClick={this.handleCancel}>
                    Cancel
                  </button>
                )}
              </Modal.Body>
            </Modal>
          )
        }}
      </AppContext.Consumer>
    )
  }
}

export default UploadPhotoModal
