/**
 * Floating Input Component
 *
 * An input field with a floating label/placeholder. The label is shown in the center 
 * of the input field and then "floats" down below the input line when the user starts 
 * typing. The component has props for placeholder, value, name, disabled, type, error,
 * and className. It also has state for focus and filled. The component updates its 
 * state based on user input and renders the input field with the appropriate classes 
 * and labels.
 */

import React from 'react'
import PropTypes from 'prop-types'

class FloatingInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      focus: false,
      filled: false,
    }
    this.onChange = this.onChange.bind(this)
    this.onKeyDown = this.onKeyDown.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.onFocus = this.onFocus.bind(this)
  }

  componentDidMount() {
    if (this.props.value.toString().length) {
      this.setState({ filled: true })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value != this.props.value) {
      this.setState({ filled: this.props.value.toString().length > 0 })
    }
  }

  onChange(event) {
    if (this.props.type === 'number') {
      const numericValue = event.target.value.replace(/[^0-9]/g, '')
      event.target.value = numericValue
    }

    this.props.onChange(event)
    this.setState({ filled: event.target.value.length > 0 })
  }

  onKeyDown(event) {
    if (this.props.type === 'number') {
      // Allow only number keys, backspace, tab, and other necessary keys
      if (!/[0-9]|Backspace|Tab|ArrowLeft|ArrowRight|Delete|Enter/.test(event.key)) {
        event.preventDefault()
      }
    }
  }

  onFocus(event) {
    this.setState({ focus: true })
  }

  onBlur(event) {
    this.setState({ focus: false })
  }

  render() {
    const { placeholder, value, name, disabled, type, error, className } = this.props
    const focus_class = this.state.focus ? `is-focused` : ``
    const filled_class = this.state.filled ? `is-filled` : ``
    const error_pos = this.state.focus || this.state.filled ? `-3.5rem` : `-2.5rem`
    return (
      <div className={`form-group mt-3 ${focus_class} ${filled_class} ${className}`}>
        <label className="label-floating" htmlFor={name}>
          {placeholder}
        </label>
        <input
          className={`form-control `}
          type={type ? type : `text`}
          name={name}
          value={value}
          onKeyDown={this.onKeyDown}
          onChange={this.onChange}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          disabled={disabled}
          autoCapitalize="off"
          autoComplete="off"
          spellCheck="false"
          autoCorrect="off"
        />
        {error !== undefined && (
          <p className={`error text-danger position-absolute`} style={{ bottom: error_pos }}>
            {error}
          </p>
        )}
      </div>
    )
  }
}

FloatingInput.propTypes = {
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
}

FloatingInput.defaultProps = {
  disabled: false,
  placeholder: `Enter Value`,
  value: ``,
  name: `floating`,
  type: `text`,
  className: ``,
}

export default FloatingInput
