import React, { useEffect, useState } from 'react'
import API from '../../utils/api'
import { getCurrentTeamData } from '../../utils/team-data'
import Modal from 'react-bootstrap/Modal'
import Link from '../link'
import { navigate } from 'gatsby'

const SvgNotification = () => {
  const [teamData, setTeamData] = useState(getCurrentTeamData())
  const [notificationCount, setNotificationCount] = useState(
    window.localStorage.getItem(`notificationsCount`) ? window.localStorage.getItem(`notificationsCount`) : 0
  )
  const [showNewNotifications, setShowNewNotifications] = useState(false)
  const [showNewNotificationModal, setShowNewNotificationModal] = useState(false)
  const [latestNotification, setLatestNotification] = useState(null)

  const [showModal, setShowModal] = useState(false)
  const handleModalShow = () => setShowModal(true)

  const handleModalClose = () => {
    setShowModal(false)
    setShowNewNotifications(false)
    setShowNewNotificationModal(false)
    localStorage.setItem(`notificationsUpdated`, ``)
    localStorage.setItem(`notificationsModal`, ``)
  }

  const handleModalCloseReadLater = () => {
    setShowModal(false)
    setShowNewNotificationModal(false)
    localStorage.setItem(`notificationsModal`, ``)
  }

  const handleIconClick = () => {
    setShowModal(false)
    setShowNewNotifications(false)
    localStorage.setItem(`notificationsUpdated`, ``)
    navigate(`/notifications/`)
  }

  useEffect(() => {
    setNotificationCount(localStorage.getItem(`notificationsCount`))
    setShowNewNotifications(localStorage.getItem(`notificationsUpdated`))
    setShowNewNotificationModal(localStorage.getItem(`notificationsModal`))
    setLatestNotification(
      localStorage.getItem(`notifications`) ? JSON.parse(localStorage.getItem(`notifications`))[0] : null
    )

    const interval = setInterval(() => {
      //console.log(`This will run every 60 seconds!`)

      if (teamData.hunt_id !== undefined) {
        API.get(`/hunt/${teamData.hunt_id}/notifications/`, {
          headers: {},
        }).then(res => {
          console.log(res.data)
          console.log(res.data.results.count)
          console.log(notificationCount)
          if (res.data.results.count > notificationCount) {
            localStorage.setItem(`notifications`, JSON.stringify(res.data.results.notifications))
            localStorage.setItem(`notificationsCount`, JSON.stringify(res.data.results.count))
            localStorage.setItem(`notificationsUpdated`, true)
            localStorage.setItem(`notificationsModal`, true)
            setNotificationCount(res.data.results.count)
            setLatestNotification(res.data.results.notifications[0])
            setShowModal(true)
            setShowNewNotificationModal(true)
          }
        })
      }
    }, 60000)
    return () => clearInterval(interval)
  }, [notificationCount])

  return (
    <>
      <span
        className="btn btn-link menu-toggle"
        onClick={handleIconClick}
        style={{
          cursor: `pointer`,
        }}>
        <span className={showNewNotifications ? `notification-icon notification-icon--new` : `notification-icon`}>
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 80 96">
            <path
              fill="#FFF"
              stroke="#F5F2ED"
              strokeWidth={1}
              d="M75.727 65.543a6.026 6.026 0 0 1-2.07-4.547v-26.84c0-9-3.5-17.453-9.86-23.797C57.286 3.848 48.617.351 39.415.504 21.18.816 6.345 16.23 6.345 34.867v26.129a6.036 6.036 0 0 1-2.067 4.547A10.552 10.552 0 0 0 .657 73.5c0 4.613 3.754 8.367 8.367 8.367H29.22v2.851C29.22 90.664 34.055 95.5 40 95.5s10.781-4.835 10.781-10.78v-2.852h20.195c4.614 0 8.367-3.754 8.367-8.367 0-3.055-1.32-5.953-3.617-7.957h.001ZM46.258 84.72A6.267 6.267 0 0 1 40 90.977a6.264 6.264 0 0 1-6.257-6.258v-2.851h12.52l-.005 2.851Zm24.72-7.375H9.023A3.849 3.849 0 0 1 5.18 73.5c0-1.742.754-3.402 2.07-4.547a10.552 10.552 0 0 0 3.622-7.957l-.004-26.129c0-16.184 12.84-29.57 28.625-29.836.172-.003.344-.003.512-.003 7.78 0 15.078 3.02 20.594 8.535 5.504 5.492 8.53 12.805 8.53 20.598V61c0 3.05 1.321 5.953 3.622 7.957a6.026 6.026 0 0 1 2.07 4.547 3.848 3.848 0 0 1-3.844 3.84Z"
            />
          </svg>
        </span>
      </span>

      <Modal show={showModal} onHide={handleModalClose} centered scrollable={true} aria-labelledby="send-notification">
        <Modal.Header className={`card-header`} closeButton>
          <Modal.Title id="progress-modal-title" className={`card-title mb-2`}>
            {latestNotification && latestNotification.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={`card-body bg-white text-dark`}>
          {latestNotification && latestNotification.message}
        </Modal.Body>
        <Modal.Footer>
          <button className={`btn btn-block btn-tiny mt-4`} onClick={handleModalClose}>
            Mark As Read
          </button>

          <button className={`btn btn-block btn-tiny mt-4`} onClick={handleModalCloseReadLater}>
            Read Later
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default SvgNotification
