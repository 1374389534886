/**
 * Done Button Component
 *
 * Check if the user is the current captain, and then display the button to go
 * to the Finish page. Shows at the bottom of the index/overview page.
 */

import React from 'react'
import { navigate } from 'gatsby'
import Fade from 'react-reveal/Fade'
import AppContext from '../utils/context'
import { isTeamCaptain } from '../utils/team-data'

class DoneButton extends React.Component {
  render() {
    return (
      <AppContext.Consumer>
        {({ hunt_status }) => {
          const isCaptain = isTeamCaptain()
          if (!isCaptain || hunt_status == `ended`) {
            return <></>
          }
          return (
            <div className={`fixed-done-button`}>
              <Fade up big delay={1000}>
                <button className={`btn btn-block btn-success`} onClick={() => navigate(`/finish/`)}>
                  Done?
                </button>
              </Fade>
            </div>
          )
        }}
      </AppContext.Consumer>
    )
  }
}

export default DoneButton
