/**
 * Transition Component
 *
 * Wraps other components and provides horizontal slide-in and slide-out animations
 * between pages. It uses the TransitionGroup and Transition components from the 
 * "react-transition-group" library to achieve this effect. The animation duration 
 * is set to 500ms and the styles for the entering, entered, and exiting states are 
 * defined in the getTransitionStyles object. The component receives props for children 
 * and location, and the key is set to location.pathname + location.hash to track when 
 * pages are entering and exiting the DOM.
 */

import React from 'react'
import { TransitionGroup, Transition as ReactTransition } from 'react-transition-group'

//This variable will be responsible for our animation duration
const timeout = 500

//This object contains basic styles for animation, but you can extend them to whatever you like. Be creative!
const getTransitionStyles = {
  entering: {
    position: `absolute`,
    left: 0,
    right: 0,
    transform: `translateX(100%)`,
  },
  entered: {
    //position: 'relative',
    transition: `transform ${timeout}ms ease-in-out`,
    transform: `translateX(0)`,
  },
  exiting: {
    //position: 'absolute',
    transition: `all ${timeout}ms ease-in-out`,
    transform: `translateX(-100%)`,
  },
}

class Transition extends React.PureComponent {
  render() {
    const { children, location } = this.props

    return (
      //Using TransitionGroup and ReactTransition which are both
      //coming from  'react-transition-group' and are required for transitions to work
      <TransitionGroup>
        <ReactTransition
          //the key is necessary here because our ReactTransition needs to know when pages are entering/exiting the DOM
          key={location.pathname + location.hash}
          //duration of transition
          timeout={{
            enter: timeout,
            exit: timeout,
          }}
          appear={true}>
          {//Application of the styles depending on the status of page(entering, exiting, entered) in the DOM
            status => (
              <div
                style={{
                  ...getTransitionStyles[status],
                }}>
                {children}
              </div>
            )}
        </ReactTransition>
      </TransitionGroup>
    )
  }
}

export default Transition
