import axios from 'axios'
axios.defaults.xsrfHeaderName = `X-CSRFTOKEN`
axios.defaults.xsrfCookieName = `csrftoken`

const instance = axios.create({
  baseURL: `${process.env.GATSBY_API_URL}`,
})

instance.interceptors.request.use(config => {
  config.headers[`request-startTime`] = Date.now()
  return config
})

instance.interceptors.response.use(response => {
  const start = response.config.headers[`request-startTime`]
  const milliseconds = Date.now() - start
  response.headers[`request-duration`] = milliseconds
  return response
})

export default instance
