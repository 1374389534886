/**
 * Feedback Panel Toggle Button
 *
 * Renders a button to submit feedback. It uses the AppContext to access the toggleFeedback 
 * function and passes it as a callback to the onClick event of the button. When the 
 * button is clicked, it toggles the feedback panel. This component is used in the navigation
 * 
 */

import React from 'react'
import AppContext from '../utils/context'

class Feedback extends React.Component {
  render() {
    return (
      <AppContext.Consumer>
        {({ toggleFeedback }) => (
          <button className={`btn btn-link px-0 py-0`} onClick={toggleFeedback}>
            Submit Feedback
          </button>
        )}
      </AppContext.Consumer>
    )
  }
}
export default Feedback
