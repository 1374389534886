import API from './api'
import { updateUser } from './auth'
import { getHuntStatus, getCurrentTeamData } from './team-data'

const isBrowser = typeof window !== `undefined`

const getHuntData = () => (window.localStorage.huntData ? JSON.parse(window.localStorage.huntData) : {})

export const setHuntData = data => (window.localStorage.huntData = JSON.stringify(data))

export const updateHuntData = (hunt_id, start, direction) => {
  if (!isBrowser) return false
  console.log(`fetching hunt data...`)
  return API.get(`hunt/${hunt_id}/`)
    .then(res => {
      var duration = res.headers[`request-duration`]
      // log the request duration for testing purposes
      //console.log(duration)
      updateUser({ loadDuration: duration })
      console.log(`Updating hunt data to local storage...`)
      setHuntData(res.data)
      return res.data
    })
    .then(function() {
      orderHuntQuestions(start, direction)
    })
    .catch(function(error) {
      console.log(error)
    })
}

export const getCurrentHuntData = () => isBrowser && getHuntData()

export const setCurrentHuntData = data => isBrowser && setHuntData(data)

export const getPhotosEnabled = () => {
  if (!isBrowser) return true
  let data = getCurrentHuntData()
  if (data.hunt !== undefined && data.hunt.enable_photos !== undefined) {
    return data.hunt.enable_photos
  }
  return true
}

export const getHuntQuestionDisplay = () => {
  if (!isBrowser) return 0
  const data = getCurrentHuntData()
  if (data.hunt !== undefined && data.hunt.question_display !== null) {
    return data.hunt.question_display
  }
  return 0
}

export const orderHuntQuestions = (start, direction) => {
  if (!isBrowser) return 0
  const data = getCurrentHuntData()
  const questions = data.questions
  const start_index = start - 1
  var ordered_questions = []

  // order the questions
  if (direction == `counterclockwise`) {
    // loop backwards
    var first_section = questions.slice(0, start_index + 1).reverse()
    var second_section = questions.slice(start_index + 1).reverse()
    ordered_questions = first_section.concat(second_section)
  } else {
    // loop forwards
    var first_section = questions.slice(start_index)
    var second_section = questions.slice(0, start_index)
    ordered_questions = first_section.concat(second_section)
  }

  // set the questions in the new order
  data.questions = ordered_questions
  setHuntData(data)
}
