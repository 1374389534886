import React from 'react'

const HostContext = React.createContext({
  navOpen: false,
  setNavOpen: () => {},
  toggleNav: () => {},

  bonusPointsOpen: false,
  setBonusPointsOpen: () => {},
  toggleBonusPoints: () => {},
  addPointsTeam: () => {},
  setTeamID: () => {},
  team_id: ``,

  user: {},
  hunts: [],
  loggedIn: false,
  loaded: false,

  active_hunts: [],
  previous_hunts: [],
  active_hunt: {},
  setActiveHunt: () =>
    new Promise(
      resolve => {},
      () => resolve()
    ),

  uploadModalShow: false,
  setUploadModalShow: () => {},
})

export default HostContext
